import ArchiveProjectList from "@/model/ArchiveProjectsList";
import Project, { TrafficCountStats } from "@/model/Project";
import Section from "@/model/ModelSection";
import SectionState from "@/model/ModelSectionState";
import { TranslateResult } from "vue-i18n";

export interface ProjectsState {
  sectionDetails: Section;
  ProjectsList: Array<Project> | Project[];
  userInProject: Array<object>;
  currentProject: Project;
  currentProjectSectionList: Array<Section>;
  projectStatusList: Array<ProjectStatus>;
  projectTypes: Array<Types>;
  sectionTypesList: Array<Types>;
  sectionSubTypes: [Types] | [];
  currentSectionInterval: [] | [Interval];
  displayTypesList: Array<DisplayTypes>;
  currentDisplayState: {} | SectionDisplayState;
  displayInformation: DisplayInformation | {};
  projectScenes: Array<Scene>;
  activeScene: Scene | null;
  currentSectionState: SectionState;
  allSectionsStatesForTheMap: Array<SectionState>;
  manualOverrideSignOptions: [];
  currentProjectThreshold: ProjectThreshold;
  currentProjectThresholdTable: [];
  cameraSectionLatestPicture: null | any;
  currentCameraSectionSettings: CameraSettings | null;
  currentRowOpenInTheProjectList: CurrentRowOpen | null;
  currentRowOpenMainProjectInTheProjectList: CurrentRowOpen | null;
  allAvailableCountrysOptions: Array<object>;
  archivedProjectsList: ArchiveProjectList;
  currentSectionTrafficCount: TrafficCountStats;
  currentUserCanViewProjectDetails: boolean;
  modalInfo: ModalInformation;
}
export interface ModalInformation {
  sectionId: number;
  position: "boardOne" | "boardTwo";
}

export interface Scene {
  id: number;
  name: string;
}
export interface CurrentRowOpen {
  id: number;
  name: string;
}
export interface CameraSettings {
  id: number;
  cameraId: number;
}
export interface Types {
  label: TranslateResult;
  name: string;
  id: number;
}
export interface ProjectStatus {
  label: TranslateResult;
  name: string;
  id: number;
}
export interface VolumeAnalysisVariant {
  label: TranslateResult;
  name: "Volume60Minutes" | "Volume15Minutes" | "Volume1Minutes" | string;
}
export interface DisplayTypes {
  label: TranslateResult;
  name: string;
  id: number;
}
export interface Interval {
  id: number;
  active: boolean;
  displayType: Types;
  sectionId: number;
  timeStart: object;
  timeEnd: object;
  filename: string | any;
  profileId?: string;
  holiday: boolean;
  weekdays: Array<number>;
}

export interface SectionDisplayState {
  active: boolean;
  displaySign: string;
  displayBrightness: string;
}

export interface DisplayInformation {
  name: string;
}

export class ProjectProfile {
  id?: string;
  projectId: string = "0";
  name: string = "";
  intervals: Array<Interval> = [];

  constructor(profile?: any) {
    Object.assign(this, profile);
  }
}

export interface ReportOptions {
  startDate: Date | string;
  endDate: Date | string;
  projectId?: string;
  sectionId?: any;
  boardId?: any;
}

export interface MeasureReportOptions {
  startDate: Date | string;
  endDate: Date | string;
  sectionId: string;
  boardId: string;
}

export interface ProjectThreshold {
  id: number;
  stateId: number;
  typeId: number;
  high: number;
  low: number;
  mid: number;
  observationPeriod: number;
  occupancyLimit: number;
  occupancyFactor: number;
}
