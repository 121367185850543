import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);
//@ts-ignore
if (typeof require.context === "undefined") {
  const fs = require("fs");
  const path = require("path");
  //@ts-ignore
  require.context = (base = ".", scanSubDirectories = false, regularExpression = /\.js$/) => {
    const files = {};
    //@ts-ignore
    function readDirectory(directory) {
      //@ts-ignore
      fs.readdirSync(directory).forEach((file) => {
        const fullPath = path.resolve(directory, file);

        if (fs.statSync(fullPath).isDirectory()) {
          if (scanSubDirectories) readDirectory(fullPath);

          return;
        }

        if (!regularExpression.test(fullPath)) return;
        //@ts-ignore
        files[fullPath] = true;
      });
    }
    //@ts-ignore
    readDirectory(path.resolve(__dirname, base));
    //@ts-ignore
    function Module(file) {
      return require(file);
    }

    Module.keys = () => Object.keys(files);

    return Module;
  };
}

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const dateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    med: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false
    },
    rep: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    },
    repShort: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }
  },
  de: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    med: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false
    },
    rep: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    },
    repShort: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }
  }
};

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || navigator.language || "en",
  fallbackLocale: "en" || navigator.language,
  messages: loadLocaleMessages(),
  dateTimeFormats
});
export default i18n;
